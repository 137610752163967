type Props = {
  profile_pic: string;
  phrase: string;
  name: string;
  role: string;
  location: string;
};
const TeamCard = ({ profile_pic, phrase, name, role, location }: Props) => {
  return (
    <figure className="md:flex bg-slate-100 rounded-xl p-8 md:p-0 dark:bg-slate-800 transition ease-in-out delay-200 duration-300 hover:scale-110 ">
      <img
        className="w-24 h-12 md:w-48 p-4 md:h-64 md:rounded-none rounded-full mx-auto"
        src={profile_pic}
        alt=""
        width="384"
        height="512"
      />
      <div className="pt-6 md:p-8 text-center md:text-left space-y-4">
        <blockquote>
          <p className="text-xl font-medium text-white">{phrase}</p>
        </blockquote>
        <figcaption className="font-medium">
          <div className="text-sky-500 dark:text-sky-400">{name}</div>
          <div className="text-slate-700 dark:text-slate-500">
            {role + ", " + location}
          </div>
        </figcaption>
      </div>
    </figure>
  );
};

export default TeamCard;
