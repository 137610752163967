import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./index.css";
// import HOCForRouteProps from "./hoc/HOCForRouteProps";
import Homepage from "./Homepage";
import TeamPage from "./TeamPage";
// import Signup from "./routes/Signup";
// import Login from "./routes/Login";
// import Logout from "./routes/Logout";
// import Dashboard from "./routes/Dashboard";
// import PortalResponse from "./routes/PortalResponse";
// import Card from "./routes/Card";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <BrowserRouter>
    <Routes>
      <Route index element={<Homepage />} />
      <Route path="/team" element={<TeamPage />} />
      {/* <Route path="signup" element={<HOCForRouteProps Component={Signup} />} />
      <Route path="login" element={<HOCForRouteProps Component={Login} />} />
      <Route path="logout" element={<HOCForRouteProps Component={Logout} />} />
      <Route path="dashboard" element={<HOCForRouteProps Component={Dashboard} />} />
      <Route path="portal_response" element={<HOCForRouteProps Component={PortalResponse} />} />
      <Route path="card/:slug" element={<HOCForRouteProps Component={Card} />} /> */}
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
