import React from "react";
// import { Link } from "react-router-dom";

import TeamCard from "./components/TeamCard";

// import Rop_Profile from "./assets/ropirito_profile.png";
import Sir_Jack_Profile from "./assets/sir_jack_profile.png";

function TeamPage() {
  return (
    <div className="min-h-screen flex flex-col items-center bg-slate-900">
      <h1 className="basis-9/12 mt-20 text-5xl sm:text-9xl font-bold text-white">
        Our Team
      </h1>
      <div className="grid grid-cols-2 grid-flow-row gap-4 p-20">
        <div>
          <TeamCard
            profile_pic={Sir_Jack_Profile}
            phrase={
              "My magnum dong opus will show you how I turned $35K to $1.75M (50X) in less than a year"
            }
            name="Sir Jack"
            role="CEO & Founder"
            location="SF"
          />
        </div>
        <div>
          <TeamCard
            profile_pic={Sir_Jack_Profile}
            phrase={
              "Will turn your $1k into $69k and then back to $0. I also play $SPY puts and write extensive phallic themed DD"
            }
            name="Ropirito"
            role="Software Engineer"
            location="CT"
          />
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default TeamPage;
