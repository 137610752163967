import React, { useEffect } from "react";
import { useState, useRef } from "react";
// import { Link } from "react-router-dom";

import CountUp from "react-countup";
import { motion, AnimatePresence } from "framer-motion";

import twitter from "./twitter.png";
import instagram from "./instagram.png";
import discord from "./discord.png";
// import logo from "./assets/logo512.png";
import "./App.css";

function Homepage() {
  const [count, setCount] = useState(0);
  const [disabledFlag, setDisabledFlag] = useState(false);
  const [counterToggle, setCounterToggle] = useState(true);

  let url: string;
  let banner;
  switch (process.env.REACT_APP_ENV) {
    case "DEV":
      url = "http://localhost:8000";
      break;
    case "STAGING":
      url = "https://api-staging.afterhour.com";
      banner = (
        <div className="bg-pink-500">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <p className="ml-3 font-bold text-white truncate">STAGING</p>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    case "PROD":
      url = "https://api-legacy.afterhour.com";
      break;
    default:
      url = "";
  }

  const [email, setEmail] = useState("");

  const signupForm = useRef<HTMLFormElement>(null);
  const successMsg = useRef<HTMLDivElement>(null);

  let handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDisabledFlag(true);

    try {
      let res = await fetch(`${url}/signups/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });
      if (res.status === 201) {
        if (signupForm.current) {
          signupForm.current.classList.add("hidden");
        }
        if (successMsg.current) {
          successMsg.current.classList.remove("hidden");
        }
      }
    } catch (err) {
      console.log(err);
    }
    setCount(count + 1);
    setDisabledFlag(false);
    setCounterToggle(false);
  };


  const gradient = `
  .background-animate {
    background-size: 400%;

    -webkit-animation: AnimationName 10s ease infinite;
    -moz-animation: AnimationName 10s ease infinite;
    animation: AnimationName 10s ease infinite;
    -webkit-animation-duration:10s;
    -moz-animation-duration:10s;
    animation-duration:10s;
  }

  @keyframes AnimationName {
    0%,
    100% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    25% {
      background-position: 75% 25%;
    }
  }
  `;

  useEffect(() => {
    let fetchCount = async () => {
      try {
        let res = await fetch(`${url}/signups/count`);
        let countVal = await res.json();
        setCount(countVal.signups.total);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCount();
    setTimeout(() => {
      setCounterToggle(false);
    }, 13000);
  }, [url]);

  return (
    <div className="Homepage">
      {banner}
      <style>{gradient}</style>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: "100%" }}
          animate={{ opacity: 1, y: "0%" }}
          transition={{
            delay: 0.5,
            duration: 1.5,
            y: { type: "spring", bounce: 0.3, duration: 1.5 },
            ease: "linear",
          }}
        >
          <div className="min-h-screen min-w-screen flex flex-col items-center justify-center align-middle bg-home-background bg-no-repeat bg-cover">
            {/* <h1 className="animate-wiggle basis-9/12 mb-10 mt-10 text-7xl sm:text-9xl font-bold text-center -rotate-3">
          <span className="bg-gradient-to-r text-transparent bg-clip-text from-blue-600 to-indigo-500 underline decoration-indigo-600">
            After
          </span>
          <span className="bg-gradient-to-r text-transparent bg-clip-text from-blue-600 to-indigo-500 underline decoration-indigo-600">
            Hour
          </span>
        </h1> */}
            {/* <img
          src={logo}
          className="animate-wiggle h-32 w-32 mb-20 shadow-xl shadow-slate-800/50 rounded-[44px]"
          alt="AfterHour"
        /> */}

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 2, duration: 1.5 }}
              className="flex flex-col align-center items-center justify-between w-11/12"
            >
              <p className="text-white text-center text-4xl md:text-8xl md:rotate-[-5deg] w-full mb-20 mt-12 sm:mt-4 md:mt-[175px] font-bold text-center">
                📈 AFTERHOUR 📈
              </p>
              <p className="text-sky-300 text-center text-xl md:text-4xl w-full mb-4 font-bold text-center underline decoration-double decoration-2 underline-offset-2 decoration-indigo-500">
                A stock app the world is not ready for
              </p>
              <p className="text-sky-300 text-center text-xl md:text-3xl w-full mb-6 font-bold text-center underline decoration-double decoration-2 underline-offset-2 decoration-indigo-500">
                Sign up to front run the competition 🚀
              </p>
            </motion.div>

            <motion.form
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 3, duration: 1 }}
              ref={signupForm}
              onSubmit={handleSubmit}
              className="flex flex-col sm:flex-row sm:space-x-4 w-full sm:w-1/3 items-center justify-between mb-10"
            >
              <div className="flex-grow w-9/12">
                <input
                  className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight"
                  id="email"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <button
                type="submit"
                className="bg-indigo-500 hover:bg-blue-700 text-white font-bold w-9/12 sm:w-4/12 mt-3 sm:m-0 py-3 px-4 rounded-xl focus:outline-none focus:shadow-outline"
                disabled={disabledFlag}
              >
                Get Notified
              </button>
            </motion.form>

            <div
              ref={successMsg}
              className="flex flex-col align-center items-center justify-between mb-10 w-11/12 hidden"
            >
              <p className="text-white text-center text-lg w-full mb-4 pt-2">
                Thank you for being one of the first to sign up! Looking forward
                for you to be one of our{" "}
                <strong>
                  <em>founding members</em>
                </strong>
                .
              </p>
              <p className="text-white text-center text-2xl w-full">
                In the meantime, why not join our{" "}
                <span className="text-indigo-400 text-bold text-3xl underline decoration-double decoration-2 underline-offset-2 decoration-indigo-500 drop-shadow-lg">
                  <a
                    className="text-blue-300 transition-all duration-500 ease-in-out animate-slowPulse"
                    href="https://discord.gg/zuWKyDXnxD"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Discord
                  </a>
                </span>
                &nbsp; ?
              </p>
            </div>

            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 3.3, duration: 1 }}
              className="flex flex-col align-center items-center justify-between mb-10 w-11/12"
            >
              <p className="text-white text-center text-7xl w-full mb-4 font-bold text-center">
                {counterToggle ? (
                  <CountUp
                    start={0}
                    end={count}
                    useEasing={true}
                    duration={13}
                    decimal=","
                  >
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} />
                      </div>
                    )}
                  </CountUp>
                ) : (
                  <span>{count}</span>
                )}
              </p>
              <p className="text-purple-500 text-center text-3xl w-full mb-4 font-bold text-center underline decoration-double decoration-2 underline-offset-2 decoration-indigo-500">
                On the Waiting List
              </p>
            </motion.div>
            <div className="flex flex-row space-x-4 items-center justify-center mb-6">
              <motion.a
                initial={{ scale: 0, rotate: 0, opacity: 0 }}
                animate={{ scale: 1, rotate: 1080, opacity: 1 }}
                transition={{ delay: 3.6, duration: 1.5 }}
                href="https://twitter.com/afterhour_hq"
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitter} className="h-12 w-12" alt="Twitter" />
              </motion.a>
              <motion.a
                initial={{ scale: 0, rotate: 0, opacity: 0 }}
                animate={{ scale: 1, rotate: 1080, opacity: 1 }}
                transition={{ delay: 3.6, duration: 1.5 }}
                href="https://instagram.com/afterhourhq"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagram} className="h-12 w-12" alt="Instagram" />
              </motion.a>
              <motion.a
                initial={{ scale: 0, rotate: 0, opacity: 0 }}
                animate={{ scale: 1, rotate: 1080, opacity: 1 }}
                transition={{ delay: 3.6, duration: 1.5 }}
                href="https://discord.gg/zuWKyDXnxD"
                target="_blank"
                rel="noreferrer"
              >
                <img src={discord} className="h-12 w-12" alt="Instagram" />
              </motion.a>
            </div>

            <p className="text-center text-gray-300 text-m">
              &copy;2022 AfterHour Inc. All rights reserved.
            </p>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

export default Homepage;
